@import "../node_modules/bootstrap/scss/bootstrap";
@import "colors";
@import "tiny-slider";

body {
  background: $blue;
  color: $white;
  font-size: 16px;
  line-height: 1.4;
  font-weight: normal;
  font-family: Arial;
}

.title {
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 32px;
  line-height: 1;
  text-align: center;
  margin: 0 0 20px;
  padding: 0;
}

.mt-nav {
  //margin-top: 20rem !important;
}
.mt-30px {
  margin-top: 30px;
}


.btn-search {
  background: $dblue;
  color: $white;
  &:hover {
    background: $blue;
    box-shadow: none;
    border-color: transparent;
  }
}

.navbar {
  background: $dblue;
  .nav-link {
    color: $white;
  }
}
.navbar-nav {
  justify-content: center;
  align-items: center;
  width: 100%;
}

.navbar-brand svg{
  height: 210px;
}
.headbox {
  height: 20rem;
  background: url("../img/headbg.jpg") no-repeat 50% 0;
  background-size: cover;
  .container {
    position: relative;
    padding: 50px 0;
  }
}
.headtext {
  text-align: center;
  color: $bluetitle;
  font-weight: bold;
}
.headtitle {
  font-size: 60px;
  line-height: 1;
  display: block;
}
.headstitle {
  font-size: 32px;
  line-height: 1;
}
.headsearch {
  position: absolute;
  right: 0;
  bottom: 0;
}

.flexrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flexcol {
  &:nth-child(2) {
    max-width: 70%;
  }
}

/*  slider  */

.slider__box {
  position: relative;
  max-width: 1280px;
  margin: 0 auto 50px;
}
.slider_item  img{
  max-width: 100%;
}
.slider__controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  width: 100%;
}
.sliderNavArrow {
  width: 40px;
  height: 40px;
  background: url("../img/arrow_wh.svg") no-repeat 0 0;
  background-size: contain;
  position: absolute;
  cursor: pointer;
  &.prev {
    left: 0;
  }
  &.next {
    transform: scale(-1, 1);
    right: 0;
  }
}

/*  slider  */
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus,
.btn-check:active + .btn-outline-success, .btn-outline-success:active {
  box-shadow: none;
  border-color: transparent;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
  text-decoration: none;
  &:hover {
    background: $blue;
  }
  &.active {
    background: $blue;
  }
}
.nav-item {
  position: relative;
  &:hover {
    &>ul {
      display: block;
    }
  }
  ul {
    display: none;
    position: absolute;
    background: $dblue;
    padding: 10px 0.5rem;
    list-style: none;
    width: 250px;
    z-index: 1;
  }
  a {
    color: $white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  li {
    margin-bottom: 0.2rem;
    cursor: pointer;
    &.active {
      a {
        text-decoration: underline;
      }
    }
  }
}

.card {
  background-color: transparent;
  border: 0;
}

.read-more {
  text-align: right;
  color: $white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

a {
  color: $white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.card-img-overlay {
  background: #00000075;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}


.footer {
  background: $fblue;
  padding: 30px 0;
  margin-top: 20px;
  &__autor {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
.flogo {
  svg {
    max-height: 100px;
  }
}
.ftext {
  color: $white;
  text-align: center;
  p {

  }
  b, strong {
    font-weight: bold;
  }
  a {
    color: $white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.fautor {
  color: $white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.table {
  color: $white;
}

.figure-caption {
  color: $white;
}

.hide_mobile {
  display: flex!important;
}
.show_mobile {
  display: none!important;
}

.navbar-toggler:focus {
  box-shadow: none;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .navbar-expand-lg .navbar-nav .nav-link{
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-size: 14px;
  }
  .nav-item {
    ul {
      font-size: 14px;
    }
  }
  .navbar-expand-lg .navbar-nav {
    justify-content: space-between;
  }
  .headtitle {
    font-size: 50px;
  }
  .headstitle {
    font-size: 24px;
  }
  .navbar-brand svg {
    height: 160px;
  }
  .headbox {
    height: 17rem;
  }
  .ftext {
    text-align: left;
    font-size: 14px;
  }
  .fautor {
    margin-top: 15px;
  }
}

@media (max-width: 768px) {
  .footer__autor {
    justify-content: center;
    margin-top: 15px;
  }
  .ftext {
    margin-top: 15px;
  }
  .headtext {
    line-height: 1;
    padding-right: 12px;
    padding-left: 12px;
  }
  .headtitle {
    font-size: 32px;
  }
  .headstitle {
    font-size: 16px;
  }
  .navbar-brand svg {
    height: 110px;
  }
  .headbox {
    height: auto;
    .container {
      padding: 20px 0;
    }
  }
  .headsearch {
    position: relative;
    width: 100%;
    right: initial;
  }
  .hide_mobile {
    display: none!important;
  }
  .show_mobile {
    display: flex!important;
  }
  .btn-search {
    background: #4d86c9 !important;
  }
  .navbar-light .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDMyIDMyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDpub25lO3N0cm9rZTojMDAwO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2Utd2lkdGg6MnB4O308L3N0eWxlPjwvZGVmcz48dGl0bGUvPjxnIGlkPSJjcm9zcyI+PGxpbmUgY2xhc3M9ImNscy0xIiB4MT0iNyIgeDI9IjI1IiB5MT0iNyIgeTI9IjI1Ii8+PGxpbmUgY2xhc3M9ImNscy0xIiB4MT0iNyIgeDI9IjI1IiB5MT0iMjUiIHkyPSI3Ii8+PC9nPjwvc3ZnPg==");
  }
  .navbar-light .navbar-toggler.collapsed .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
}

@media (max-width: 530px) {
  .navbar-brand svg {
    height: 90px;
  }
  .headtitle {
    font-size: 28px;
  }
  .headstitle {
    font-size: 14px;
  }
  .flexcol {
    width: 30%;
    &:nth-child(2) {
      width: 70%;
    }
  }
}

@media (max-width: 375px) {
  .navbar-brand svg {
    height: 90px;
  }
  .headtitle {
    font-size: 22px;
  }
  .headstitle {
    font-size: 12px;
  }
  .headtext {
    line-height: 0.8;
  }
  .headbox {
    height: auto;
    .container {
      padding: 20px 0;
    }
  }
  .flexcol {
    width: 30%;
    &:nth-child(2) {
      width: 70%;
    }
  }
}

